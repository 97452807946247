import { useState, useEffect } from "react";
import { Column, Header, SearchInput, StyleTag, Track } from "../../components";
import { useAppContext } from "../../contexts/AppContext";
import toast, { Toaster } from "react-hot-toast";
import backgroundTicks from "../../assets/branding/background-ticks.svg";
import Footer from "../../components/Footer";
import { RepoModal } from "../../components/RepoModal";
import {
  RepositoryToBeAdded,
  Repository,
  VerificationError,
} from "../../types";
import { Octokit } from "@octokit/rest";
import { getRedirectUri } from "../../utils/getRedirectUri";
import config from "../../config";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const ITEMS_PER_PAGE = 7;
const GITHUB_CLIENT_ID = config.github.clientId;
const REPO_OWNER = config.github.repo.owner;
const REPO_NAME = config.github.repo.name;

interface GitHubError extends Error {
  status?: number;
}

const Home: React.FC = () => {
  const { handleSearch, searchResults } = useAppContext();
  const [showRepoModal, setShowRepoModal] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationErrors, setVerificationErrors] = useState<
    VerificationError[]
  >([]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedRepos, setDisplayedRepos] = useState<Repository[]>([]);

  // Update displayed repos when page changes or search results update
  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayedRepos(searchResults.slice(startIndex, endIndex));
    setTotalPages(Math.ceil(searchResults.length / ITEMS_PER_PAGE));
  }, [currentPage, searchResults]);

  // Reset to first page when search results change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchResults.length]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state && state === localStorage.getItem("oauth_state")) {
      exchangeCodeForToken(code);
    }
  }, []);

  const exchangeCodeForToken = async (code: string) => {
    try {
      const response = await fetch(config.worker.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });

      const data = await response.json();

      if (data.access_token) {
        localStorage.setItem("github_token", data.access_token);
        localStorage.removeItem("oauth_state");
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        setShowRepoModal(true);
      } else {
        console.error("Token exchange failed:", data);
        throw new Error(data.error || "Failed to get access token");
      }
    } catch (error) {
      console.error("Error exchanging code for token:", error);
      if (config.env.isDevelopment) {
        console.log("Development mode - full error:", error);
      }
    }
  };

  const handleModalOpen = () => {
    const accessToken = localStorage.getItem("github_token");
    if (!accessToken) {
      const state = Math.random().toString(36).substring(7);
      localStorage.setItem("oauth_state", state);

      const params = new URLSearchParams({
        client_id: GITHUB_CLIENT_ID!,
        redirect_uri: getRedirectUri() || window.location.href,
        scope: "repo",
        state: state,
        allow_signup: "true",
      });

      window.location.href = `https://github.com/login/oauth/authorize?${params}`;
    } else {
      setShowRepoModal(true);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const PaginationControls = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="flex items-center justify-center space-x-4 mt-8 mb-8">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`p-2 rounded-full ${
            currentPage === 1
              ? "text-gray-400 cursor-not-allowed"
              : "text-brand-primary hover:bg-gray-100"
          }`}
          aria-label="Previous page"
        >
          <MdChevronLeft className="w-6 h-6" />
        </button>

        <div className="text-sm font-medium text-gray-700">
          Page {currentPage} of {totalPages}
        </div>

        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`p-2 rounded-full ${
            currentPage === totalPages
              ? "text-gray-400 cursor-not-allowed"
              : "text-brand-primary hover:bg-gray-100"
          }`}
          aria-label="Next page"
        >
          <MdChevronRight className="w-6 h-6" />
        </button>
      </div>
    );
  };

  const verifyAndSubmitRepositories = async (
    selectedRepos: RepositoryToBeAdded[]
  ) => {
    setVerifying(true);
    setVerificationErrors([]);
    const errors: VerificationError[] = [];

    try {
      const accessToken = localStorage.getItem("github_token");
      if (!accessToken) {
        handleModalOpen();
        return;
      }

      const octokit = new Octokit({ auth: accessToken });
      const projectsPath = `src/config/${config.projects}`;

      for (const repo of selectedRepos) {
        try {
          // Check for valid8.json
          try {
            const { data: valid8File } = await octokit.repos.getContent({
              owner: repo.owner.login,
              repo: repo.name,
              path: "valid8.json",
            });

            if (!("content" in valid8File)) {
              throw new Error("valid8.json file is not a file");
            }

            try {
              const valid8Content = JSON.parse(atob(valid8File.content));
              if (!valid8Content) {
                throw new Error("valid8.json is empty");
              }
            } catch (jsonError) {
              throw new Error("valid8.json contains invalid JSON");
            }
          } catch (valid8Error: any) {
            throw new Error(
              valid8Error.status === 404
                ? `Repository ${repo.name} does not contain valid8.json`
                : `Error checking valid8.json: ${valid8Error.message}`
            );
          }

          // Get the current content of projects.json
          const { data: fileData } = await octokit.repos.getContent({
            owner: REPO_OWNER,
            repo: REPO_NAME,
            path: projectsPath,
          });

          if ("content" in fileData) {
            // Decode and parse the current content
            const content = atob(fileData.content);
            const projectsData = JSON.parse(content);

            // Ensure projects object exists
            if (!projectsData.projects) {
              projectsData.projects = {};
            }

            // Create the full repository name
            const fullName = `${repo.owner.login}/${repo.name}`;

            // Check for duplicate
            if (projectsData.projects[fullName]) {
              throw new Error(
                `Repository ${fullName} is already in the project listing`
              );
            }

            // Check for duplicate by repo_url (as a fallback)
            const isDuplicateUrl = Object.values(projectsData.projects).some(
              (project: any) => project.repo_url === repo.html_url
            );
            if (isDuplicateUrl) {
              throw new Error(
                `Repository ${fullName} is already listed with a different name`
              );
            }

            // Prepare new project data
            const newProject = {
              full_name: fullName,
              repo_name: repo.name,
              username: repo.owner.login,
              repo_url: repo.html_url,
            };

            // Add the new project
            projectsData.projects[fullName] = newProject;

            // Create a new branch
            const branchName = `add-${repo.name}-${Date.now()}`;
            try {
              const mainRef = await octokit.git.getRef({
                owner: REPO_OWNER,
                repo: REPO_NAME,
                ref: "heads/main",
              });

              await octokit.git.createRef({
                owner: REPO_OWNER,
                repo: REPO_NAME,
                ref: `refs/heads/${branchName}`,
                sha: mainRef.data.object.sha,
              });

              // Update the file in the new branch
              await octokit.repos.createOrUpdateFileContents({
                owner: REPO_OWNER,
                repo: REPO_NAME,
                path: projectsPath,
                message: `Add ${fullName} to project listing`,
                content: btoa(JSON.stringify(projectsData, null, 2)),
                branch: branchName,
                sha: fileData.sha,
              });

              // Create the pull request
              const { data: pr } = await octokit.pulls.create({
                owner: REPO_OWNER,
                repo: REPO_NAME,
                title: `Add ${fullName} to project listing`,
                head: branchName,
                base: "main",
                body: `
Adding ${fullName} to the project listing.

- Repository: ${repo.html_url}
- Owner: ${repo.owner.login}
- Valid8: ✅ Verified

\`\`\`json
${JSON.stringify(newProject, null, 2)}
\`\`\`
                `,
              });

              toast.success(
                <div>
                  <p>Created PR for {fullName}</p>
                  <a
                    href={pr.html_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm underline"
                  >
                    View Pull Request
                  </a>
                </div>,
                {
                  duration: 7000,
                  position: "bottom-right",
                  style: {
                    background: "#0D9488",
                    color: "#fff",
                  },
                }
              );
            } catch (branchError) {
              throw new Error(
                `Failed to create branch: ${
                  branchError instanceof Error
                    ? branchError.message
                    : "Unknown error"
                }`
              );
            }
          }
        } catch (error) {
          const gitHubError = error as GitHubError;
          if (gitHubError.status === 401) {
            localStorage.removeItem("github_token");
            handleModalOpen();
            return;
          }

          errors.push({
            repo,
            error:
              error instanceof Error ? error.message : "Failed to create PR",
          });
        }
      }

      if (errors.length === 0) {
        setShowRepoModal(false);
      } else {
        setVerificationErrors(errors);
      }
    } catch (error) {
      const gitHubError = error as GitHubError;
      if (gitHubError.status === 401) {
        localStorage.removeItem("github_token");
        handleModalOpen();
        return;
      }

      setVerificationErrors(
        selectedRepos.map((repo) => ({
          repo,
          error: error instanceof Error ? error.message : "Verification failed",
        }))
      );
    } finally {
      setVerifying(false);
    }
  };

  return (
    <div>
      <StyleTag />
      <Toaster />
      <div className="transition-all duration-200">
        <>
          <Header onAddRepo={handleModalOpen} />
          <Column
            className="gap-[75px]"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <>
              <div className="mt-[75px] lg:w-[40%] w-full mx-auto flex flex-col justify-center items-center space-y-6 relative">
                <img
                  src={backgroundTicks}
                  className="absolute hidden sm:block sm:scale-150 xl:scale-125 w-full h-full"
                  alt="background ticks"
                />
                <h2 className="text-4xl lg:text-5xl !leading-[1.1] font-normal text-center">
                  <span className="inline-block text-shade-secondary">
                    Find Github Repos
                  </span>{" "}
                  <span className="text-shade-primary font-semibold">
                    using Valid8
                  </span>
                </h2>
                <SearchInput onSearch={handleSearch} />
              </div>
              <Track
                repositories={displayedRepos}
                onAddRepo={handleModalOpen}
              />
              <PaginationControls />
            </>
          </Column>
          <Footer />
        </>
      </div>

      {showRepoModal && (
        <RepoModal
          onClose={() => {
            setShowRepoModal(false);
            setVerificationErrors([]);
          }}
          onSubmit={(repos: Repository[]) => {
            // Transform Repository[] to RepositoryToBeAdded[]
            const reposToAdd: RepositoryToBeAdded[] = repos.map((repo) => ({
              owner: { login: repo.username },
              name: repo.repo_name,
              html_url: repo.repo_url,
              full_name: `${repo.username}/${repo.repo_name}`,
              onSubmit: () => {},
            }));
            verifyAndSubmitRepositories(reposToAdd);
          }}
          verifying={verifying}
          verificationErrors={verificationErrors}
        />
      )}
    </div>
  );
};

export default Home;
