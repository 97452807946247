import { Button } from "./Button";
import logoImage from "../assets/branding/logo.svg";
import GithubIcon from "./Icons/GithubIcon";
import PlusIcon from "./Icons/PlusIcon";

export const Header = ({ onAddRepo }: { onAddRepo: () => void }) => {
  const buttonText = "Add a repo";

  return (
    <header className="max-w-[750px] relative flex mx-auto pl-6 py-3 pr-3 border border-shade-border shadow-[0px_2px_6px_#E9EAEB] items-center justify-between w-full bg-white rounded-full h-[60px] ">
      <img src={logoImage} alt="Valid8 Logo" className="h-[32px]" />
      <div className="absolute left-1/2 transform -translate-x-1/2 md:flex items-center space-x-6 hidden ">
        <a href="https://discord.gg/gGgEe2tC" rel="noreferrer" target="_blank" className="transition hover:text-shade-primary">
          Support
        </a>
        <a href="https://https://github.com/turbin3/valid8" rel="noreferrer" target="_blank" className="transition hover:text-shade-primary">
          GitHub
        </a>
      </div>

      <Button className="!h-[36px] !rounded-2xl" onClick={onAddRepo}>
        <GithubIcon />
        <span>{buttonText}</span>
        <PlusIcon />
      </Button>
    </header>
  );
};
