import { useRef, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RepositoryToBeAdded, RepoModalProps } from "../types";
import { FaSpinner } from "react-icons/fa";
import { Button } from "./Button";
import Modal from "@mui/material/Modal";
import { SearchInput } from "./SearchInput";

export const RepoModal = ({
  onClose,
  onSubmit,
  verifying = false,
  verificationErrors = [],
}: RepoModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [repositories, setRepositories] = useState<RepositoryToBeAdded[]>([]);
  const [selectedRepos, setSelectedRepos] = useState<RepositoryToBeAdded[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchRepositories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  const fetchRepositories = async () => {
    try {
      const accessToken = localStorage.getItem("github_token");

      if (!accessToken) {
        setError("No GitHub access token available");
        setLoading(false);
        return;
      }

      const response = await fetch(
        "https://api.github.com/user/repos?per_page=100",
        {
          headers: {
            Authorization: `token ${accessToken}`, // Using 'token' for GitHub API
            Accept: "application/vnd.github.v3+json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("github_token");
          setError("GitHub authentication failed. Please try again.");
          // You can add a callback to parent to handle reauth
          // onAuthError?.(); // If you add this prop
        } else {
          throw new Error("Failed to fetch repositories");
        }
        return;
      }

      const repos: RepositoryToBeAdded[] = await response.json();
      setRepositories(repos);
      setError(null);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Failed to fetch repositories");
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleRepository = (repo: RepositoryToBeAdded) => {
    setSelectedRepos((prev) =>
      prev.some((r) => r.full_name === repo.full_name)
        ? prev.filter((r) => r.full_name !== repo.full_name)
        : [...prev, repo]
    );
  };

  const filteredRepos = repositories.filter(
    (repo) =>
      repo.owner.login.toLowerCase().includes(searchTerm.toLowerCase()) ||
      repo.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      open={true}
      onClose={onClose}
      className="flex items-center justify-center"
      slotProps={{
        backdrop: {
          style: {
            backdropFilter: "blur(8px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      }}
    >
      <div
        ref={modalRef}
        className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 h-[600px] flex flex-col relative"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">
            Select Repositories
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <AiOutlineClose size={24} />
          </button>
        </div>

        {verificationErrors.length > 0 && (
          <div className="mb-4 p-4 bg-red-50 border-l-4 border-red text-red rounded-md">
            <p className="font-medium">Failed to verify repositories:</p>
            <ul className="mt-2 list-disc list-inside">
              {verificationErrors.map((error, index) => (
                <li key={index}>
                  {error.repo.name}: {error.error}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="mb-4">
          <SearchInput
            onSearch={setSearchTerm}
            placeholder="Search repositories..."
            className="w-full"
          />
        </div>

        <div className="flex-grow overflow-auto mb-4">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <FaSpinner
                className="inline-block animate-spin"
                color="hotPink"
                size={36}
              />
            </div>
          ) : error ? (
            <div className="flex flex-col justify-center items-center h-full text-center space-y-4">
              {error.includes("token") || error.includes("expired") ? (
                <>
                  <p className="text-gray-600">
                    Session expired. Reconnecting to GitHub...
                  </p>
                  <FaSpinner
                    className="inline-block animate-spin"
                    color="hotPink"
                    size={36}
                  />
                </>
              ) : (
                <p className="text-red-600">{error}</p>
              )}
            </div>
          ) : (
            <div className="space-y-2">
              {filteredRepos.map((repo) => (
                <label
                  key={repo.full_name}
                  className={`flex items-center p-3 hover:bg-gray-50 rounded-md cursor-pointer
                    ${
                      verificationErrors.some(
                        (e) => e.repo.full_name === repo.full_name
                      )
                        ? "border-l-4 border-red"
                        : ""
                    }`}
                >
                  <div className="relative flex items-center">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={selectedRepos.some(
                        (r) => r.full_name === repo.full_name
                      )}
                      onChange={() => toggleRepository(repo)}
                    />
                    <div
                      className={`w-5 h-5 rounded flex items-center justify-center ${
                        selectedRepos.some(
                          (r) => r.full_name === repo.full_name
                        )
                          ? "bg-hotPink"
                          : "border-2 border-gray-300"
                      }`}
                    >
                      {selectedRepos.some(
                        (r) => r.full_name === repo.full_name
                      ) && (
                        <svg
                          className="w-3 h-3 text-white fill-current"
                          viewBox="0 0 20 20"
                        >
                          <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="ml-3">
                    <div className="font-medium text-gray-700">{repo.name}</div>
                    <div className="text-sm text-gray-500">
                      {repo.full_name}
                    </div>
                  </div>
                </label>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end gap-3 pt-4 border-t">
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              onSubmit(
                selectedRepos.map((repo) => ({
                  repo_name: repo.name,
                  username: repo.owner.login,
                  repo_url: repo.html_url,
                }))
              )
            }
            disabled={selectedRepos.length === 0}
            loading={verifying}
          >
            {verifying
              ? "Verifying..."
              : `Add Selected (${selectedRepos.length})`}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
