import "./App.css";
import AppProvider from "./contexts/AppContext";
import Home from "./pages/Home";

function App() {
  return (
    <AppProvider>
      <div className="wrapper">
        <Home />
      </div>
    </AppProvider>
  );
}

export default App;
