import React, { useState, useEffect, useCallback } from "react";
import { Repository } from "../types";
import { TileModal } from "./TileModal";
import StarIcon from "./Icons/StarIcon";
import { Button } from "./Button";
import ArrowTopRight from "./Icons/ArrowTopRight";
import CopyLinkIcon from "./Icons/CopyLinkIcon";
import TickIcon from "./Icons/TickIcon";
import { Octokit } from "@octokit/rest";

interface StarCache {
  count: number;
  timestamp: number;
}

// Cache duration - 1 hour
const CACHE_DURATION = 60 * 60 * 1000;
// Maximum retry attempts
const MAX_RETRIES = 3;
// Delay between retries (in ms) - starts at 1s, then 2s, then 4s
const RETRY_DELAY = 1000;

// Global cache object
const starCountCache: { [key: string]: StarCache } = {};

export const Tile: React.FC<Repository> = ({
  full_name,
  repo_name,
  username,
  repo_url,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [starCount, setStarCount] = useState<number | null>(null);
  const [isLoadingStars, setIsLoadingStars] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const fetchStarCount = useCallback(
    async (retryCount = 0): Promise<number> => {
      if (!full_name) throw new Error("Repository name is required");

      try {
        const accessToken = localStorage.getItem("github_token");
        const octokit = new Octokit({
          auth: accessToken || undefined,
        });

        const [owner, repo] = full_name.split("/");
        const { data } = await octokit.repos.get({
          owner,
          repo,
        });

        return data.stargazers_count;
      } catch (error: any) {
        // Handle rate limiting
        if (
          error.status === 403 &&
          error.response?.headers?.["x-ratelimit-remaining"] === "0"
        ) {
          const resetTime = error.response?.headers?.["x-ratelimit-reset"];
          const waitTime = resetTime
            ? Number(resetTime) * 1000 - Date.now()
            : 0;
          throw new Error(
            `Rate limit exceeded. Resets in ${Math.ceil(
              waitTime / 1000 / 60
            )} minutes`
          );
        }

        // Handle unauthorized
        if (error.status === 401) {
          localStorage.removeItem("github_token");
          throw new Error("GitHub token expired or invalid");
        }

        // Handle not found
        if (error.status === 404) {
          throw new Error("Repository not found");
        }

        // Retry logic for other errors
        if (retryCount < MAX_RETRIES) {
          await delay(RETRY_DELAY * Math.pow(2, retryCount));
          return fetchStarCount(retryCount + 1);
        }

        throw error;
      }
    },
    [full_name]
  );

  useEffect(() => {
    const getStarCount = async () => {
      if (!full_name) return;

      setIsLoadingStars(true);

      try {
        // Check cache first
        const cached = starCountCache[full_name];
        if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
          setStarCount(cached.count);
          setIsLoadingStars(false);
          return;
        }

        const count = await fetchStarCount();

        // Update cache
        starCountCache[full_name] = {
          count,
          timestamp: Date.now(),
        };

        setStarCount(count);
      } catch (error) {
        console.error("Error fetching star count:", error);
        setStarCount(null);
      } finally {
        setIsLoadingStars(false);
      }
    };

    void getStarCount();
  }, [full_name, fetchStarCount]);

  const linkCopiedTimeout = () => {
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 1000);
  };

  const onOpen = () => setIsModalOpen(true);

  const formatStarCount = (count: number): string => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}m`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}k`;
    }
    return count.toString();
  };

  return (
    <>
      <div className="flex flex-col space-y-3 relative bg-white py-6 px-5 border w-full aspect-[4/3] xl:aspect-[1/1] border-shade-border rounded-2xl shadow-[0,2px,6px,rgba(233,234,235,0.5)]">
        <div className="flex flex-col space-y-1">
          <div className="flex items-center space-x-1.5 text-brand-primary">
            <StarIcon />
            <div
              className="relative"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <span className="font-mono text-shade-secondary">
                {isLoadingStars ? (
                  <span className="animate-pulse">-- Stars</span>
                ) : starCount === null ? (
                  "-- Stars"
                ) : (
                  `${formatStarCount(starCount)} Stars`
                )}
              </span>
              {showTooltip && starCount !== null && (
                <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap">
                  {starCount.toLocaleString()} stars
                </div>
              )}
            </div>
          </div>
          <div className="text-shade-primary font-semibold text-lg">
            {repo_name}
          </div>
        </div>
        <div className="flex items-center space-x-1.5 !mt-auto">
          <img
            src={`https://avatars.githubusercontent.com/${username}`}
            className="w-[24px] h-[24px] rounded-full"
            alt={username}
          />
          <span className="truncate max-w-[66%]">{username}</span>
        </div>
        <div className="flex space-x-1.5">
          <Button
            onClick={onOpen}
            theme="primary"
            className="!py-1.5 !space-x-2.5 !w-full"
          >
            <span>Open Repo</span>
            <ArrowTopRight />
          </Button>
          <Button
            theme="secondary"
            onClick={() => {
              linkCopiedTimeout();
              navigator.clipboard.writeText(repo_url);
            }}
          >
            {!linkCopied ? <CopyLinkIcon /> : <TickIcon />}
          </Button>
        </div>
      </div>
      {isModalOpen && (
        <TileModal
          full_name={full_name ?? ""}
          repo_name={repo_name ?? ""}
          username={username ?? ""}
          repo_url={repo_url ?? ""}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
