export default function GithubIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99994 1.6275C7.21366 1.64438 5.49092 2.29262 4.13662 3.45752C2.78233 4.62242 1.88378 6.22886 1.60001 7.99254C1.31624 9.75623 1.66554 11.5634 2.5861 13.0944C3.50665 14.6253 4.9391 15.7812 6.62994 16.3575C7.00494 16.4325 7.13995 16.2 7.13995 15.9975C7.13995 15.795 7.13995 15.2475 7.13995 14.595C5.04744 15.045 4.61244 13.695 4.61244 13.695C4.47527 13.2314 4.16694 12.8372 3.74994 12.5925C3.06744 12.1275 3.80244 12.1275 3.80244 12.1275C4.03903 12.1574 4.2658 12.2404 4.46581 12.3703C4.66581 12.5001 4.83388 12.6735 4.95744 12.8775C5.05829 13.0672 5.19652 13.2344 5.36379 13.3692C5.53106 13.5039 5.7239 13.6034 5.93067 13.6616C6.13744 13.7197 6.35385 13.7354 6.56684 13.7076C6.77984 13.6799 6.985 13.6092 7.16994 13.5C7.19773 13.1159 7.36592 12.7555 7.64244 12.4875C5.97744 12.315 4.22244 11.6625 4.22244 8.7375C4.20802 7.99206 4.47593 7.26871 4.97244 6.7125C4.7427 6.05963 4.76951 5.34386 5.04744 4.71C5.04744 4.71 5.67744 4.5075 7.10994 5.46C7.72778 5.30613 8.3634 5.23551 8.99994 5.25C9.63288 5.25305 10.2629 5.33621 10.8749 5.4975C12.3074 4.5225 12.9374 4.7475 12.9374 4.7475C13.2178 5.3804 13.2473 6.09618 13.0199 6.75C13.5224 7.3026 13.7913 8.02841 13.7699 8.775C13.7699 11.685 12.0149 12.3225 10.3349 12.525C10.5161 12.7081 10.6555 12.9283 10.7435 13.1704C10.8316 13.4125 10.8662 13.6708 10.8449 13.9275C10.8449 14.94 10.8449 15.7575 10.8449 16.005C10.8449 16.2525 10.9799 16.44 11.3549 16.365C13.05 15.793 14.4876 14.639 15.4127 13.1078C16.3377 11.5766 16.6904 9.76716 16.4081 8.00062C16.1258 6.23407 15.2268 4.62466 13.8706 3.45797C12.5145 2.29128 10.7888 1.64276 8.99994 1.6275Z"
        fill="currentColor"
      />
    </svg>
  );
}
