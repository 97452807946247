export default function SearchIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.25 16C15.058 16 14.866 15.927 14.72 15.78L10.755 11.815C10.462 11.522 10.462 11.047 10.755 10.754C11.048 10.461 11.523 10.461 11.816 10.754L15.781 14.719C16.074 15.012 16.074 15.487 15.781 15.78C15.635 15.926 15.443 16 15.251 16H15.25Z"
        fill="currentColor"
      />
      <path
        d="M7.75 13.5C4.58 13.5 2 10.92 2 7.75C2 4.58 4.58 2 7.75 2C10.92 2 13.5 4.58 13.5 7.75C13.5 10.92 10.92 13.5 7.75 13.5ZM7.75 3.5C5.407 3.5 3.5 5.407 3.5 7.75C3.5 10.093 5.407 12 7.75 12C10.093 12 12 10.093 12 7.75C12 5.407 10.093 3.5 7.75 3.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
