import logo from "../assets/branding/logo.svg";
import turbineLogo from "../assets/branding/turbine-logo.svg";

export default function Footer() {
  return (
    <footer className="mt-[150px] flex flex-col space-y-[50px] bg-transparent md:bg-white md:border border-shade-border py-[50px] px-6 md:px-12 xl:px-24 w-full h-full rounded-2xl md:rounded-[32px]">
      <div className="flex items-center justify-between w-full flex-col space-y-6 sm:space-y-0 sm:flex-row">
        <img src={logo} alt="Valid8 Logo" className="h-[32px]" />
        <div className="flex items-center space-x-6">
          <a href="https://discord.gg/gGgEe2tC" rel="noreferrer" target="_blank" className="transition hover:text-shade-primary">
            Discord
          </a>
          <a href="https://x.com/solanaturbine" rel="noreferrer" target="_blank" className="transition hover:text-shade-primary">
            Twitter
          </a>
          <a href="https://github.com/turbin3/valid8" rel="noreferrer" target="_blank" className="transition hover:text-shade-primary">
            Github
          </a>
        </div>
      </div>
      <div className="h-[1px] w-full bg-shade-border"></div>

      <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 items-center justify-between w-full">
        <span className="text-shade-mute">© Copyright Turbin3, 2024</span>
        <div className="flex items-center space-x-4">
          <span>A project by</span>
          <img src={turbineLogo} alt="Turbine Logo" className="h-[24px]" />
        </div>
      </div>
    </footer>
  );
}
