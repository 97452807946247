export default function StarIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1C4.589 1 1 4.589 1 9C1 13.411 4.589 17 9 17C13.411 17 17 13.411 17 9C17 4.589 13.411 1 9 1ZM12.526 9.697L10.499 10.499L9.697 12.526C9.584 12.812 9.307 13 9 13C8.693 13 8.416 12.812 8.303 12.526L7.501 10.499L5.474 9.697C5.188 9.584 5 9.307 5 9C5 8.693 5.188 8.416 5.474 8.303L7.501 7.501L8.303 5.474C8.529 4.902 9.472 4.902 9.698 5.474L10.5 7.501L12.527 8.303C12.813 8.416 13.001 8.693 13.001 9C13.001 9.307 12.813 9.584 12.527 9.697H12.526Z"
        fill="currentColor"
      />
    </svg>
  );
}
