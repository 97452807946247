interface Config {
  github: {
    clientId: string;
    redirectUri: string;
    repo: {
      owner: string;
      name: string;
    };
  };
  worker: {
    url: string;
  };
  env: {
    isDevelopment: boolean;
    isProduction: boolean;
  };
  isDev: boolean;
  projects: string;
}

const isDevelopment = process.env.NODE_ENV === "development";
const isProduction = process.env.NODE_ENV === "production";

const config: Config = {
  github: {
    clientId: process.env.REACT_APP_GITHUB_CLIENT_ID || "",
    redirectUri: isDevelopment
      ? "http://localhost:3000"
      : process.env.REACT_APP_PRODUCTION_URL || "",
    repo: {
      owner: process.env.REACT_APP_REPO_OWNER || "3uild-3thos",
      name: process.env.REACT_APP_REPO_NAME || "valid8-frontend",
    },
  },
  worker: {
    url:
      process.env.REACT_APP_WORKER_URL ||
      "https://github-oauth-proxy.valid8-auth.workers.dev",
  },
  env: {
    isDevelopment,
    isProduction,
  },
  projects: isDevelopment ? "dev-projects.json" : "projects.json",
  isDev: isDevelopment,
};

export const { github, worker, env, projects, isDev } = config;

export default config;
