export default function PlusIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_1831)">
        <path
          d="M10.75 6.75H1.25C0.836 6.75 0.5 6.414 0.5 6C0.5 5.586 0.836 5.25 1.25 5.25H10.75C11.164 5.25 11.5 5.586 11.5 6C11.5 6.414 11.164 6.75 10.75 6.75Z"
          fill="currentColor"
        />
        <path
          d="M6 11.5C5.586 11.5 5.25 11.164 5.25 10.75V1.25C5.25 0.836 5.586 0.5 6 0.5C6.414 0.5 6.75 0.836 6.75 1.25V10.75C6.75 11.164 6.414 11.5 6 11.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_1831">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
