import { Row } from "./Row";
import { Tile } from "./Tile";
import { Grid } from "./Grid";
import { AddRepo } from "./AddRepo";
import { Repository } from "../types";

interface TrackProps {
  onAddRepo: () => void;
  repositories: Repository[];
}

export const Track = ({ onAddRepo, repositories }: TrackProps) => {
  return (
    <div className="w-full flex items-center">
      {repositories.length === 0 ? (
        <Row
          className="w-full"
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <p className="text-white">No results found</p>
        </Row>
      ) : (
        <Grid className="!grid-cols-1 md:!grid-cols-2 xl:!grid-cols-4 !gap-6">
          <>
            <AddRepo onAddRepo={onAddRepo} />
            {repositories.map(
              ({ full_name, repo_name, username, repo_url }) => (
                <Tile
                  key={full_name}
                  full_name={full_name}
                  repo_name={repo_name}
                  username={username}
                  repo_url={repo_url}
                />
              )
            )}
          </>
        </Grid>
      )}
    </div>
  );
};
