import React, { useRef, useEffect, useState } from "react";
import { Repository } from "../types";
import { AiOutlineClose, AiOutlineCopy } from "react-icons/ai";
import { Modal, Box } from "@mui/material";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

SyntaxHighlighter.registerLanguage("json", json);

interface TileModalProps extends Repository {
  onClose: () => void;
}

export const TileModal: React.FC<TileModalProps> = ({
  repo_name,
  username,
  repo_url,
  full_name,
  onClose,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [valid8Content, setValid8Content] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const [copiedInstall, setCopiedInstall] = useState(false);

  useEffect(() => {
    const fetchValid8Content = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Try 'main' branch first
        let response = await fetch(
          `https://raw.githubusercontent.com/${full_name}/main/valid8.json`
        );

        // If 'main' fails, try 'master'
        if (!response.ok && response.status === 404) {
          response = await fetch(
            `https://raw.githubusercontent.com/${full_name}/master/valid8.json`
          );
        }

        if (!response.ok) {
          throw new Error(`Failed to fetch valid8.json (${response.status})`);
        }

        const data = await response.json();
        setValid8Content(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to fetch valid8.json"
        );
      } finally {
        setIsLoading(false);
      }
    };
    void fetchValid8Content();
  }, [full_name]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleCopy = () => {
    if (valid8Content) {
      navigator.clipboard.writeText(JSON.stringify(valid8Content, null, 2));
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleCopyInstall = () => {
    const installCommand = `valid8 install ${username}/${repo_name}`;
    navigator.clipboard.writeText(installCommand);
    setCopiedInstall(true);
    setTimeout(() => setCopiedInstall(false), 2000);
  };

  const highlightStyle = {
    margin: 0,
    padding: 0,
    background: "transparent",
    fontSize: "14px",
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-title"
      className="flex items-center justify-center"
      slotProps={{
        backdrop: {
          style: {
            backdropFilter: "blur(8px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      }}
    >
      <Box className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[90vh] flex flex-col outline-none">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">{repo_name}</h2>
          <button onClick={onClose} title="Close">
            <AiOutlineClose
              className="text-gray-600 hover:text-gray-800 cursor-pointer"
              size={24}
            />
          </button>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Owner</h3>
          <p className="text-gray-600">{username}</p>
        </div>

        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Link</h3>
          <a
            href={repo_url}
            target="_blank"
            rel="noreferrer"
            className="text-blue hover:underline break-all"
          >
            {repo_url}
          </a>
        </div>

        <div className="mt-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold text-gray-700">
              Installation
            </h3>
          </div>
          <div className="bg-darkGray p-4 rounded-md font-mono text-sm relative">
            <button
              onClick={handleCopyInstall}
              className="absolute top-2 right-2 flex items-center justify-center text-sm text-white hover:text-hotPink font-sans"
            >
              <AiOutlineCopy className="mr-1" />
              {copiedInstall ? "Copied!" : "Copy"}
            </button>
            <SyntaxHighlighter
              language="bash"
              style={vs2015}
              customStyle={highlightStyle}
            >
              {`valid8 install ${username}/${repo_name}`}
            </SyntaxHighlighter>
          </div>
        </div>

        <div className="mt-4 flex-grow flex flex-col overflow-hidden">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold text-gray-700">Valid8.json</h3>
          </div>
          <div className="bg-darkGray p-4 rounded-md overflow-auto flex-grow min-h-[296px] relative">
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-pulse text-lightGray text-[14px]">
                  Loading valid8.json...
                </div>
              </div>
            ) : error ? (
              <div className="text-red text-sm text-center text-[14px]">
                {error}
              </div>
            ) : (
              <>
                <button
                  onClick={handleCopy}
                  className="absolute top-2 right-2 flex items-center justify-center text-sm text-white hover:text-hotPink font-sans"
                >
                  <AiOutlineCopy className="mr-1" />
                  {copied ? "Copied!" : "Copy"}
                </button>
                <SyntaxHighlighter
                  language="json"
                  style={vs2015}
                  customStyle={highlightStyle}
                >
                  {JSON.stringify(valid8Content, null, 2)}
                </SyntaxHighlighter>
              </>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
