import AddRepoIcon from "./Icons/AddRepoIcon";

export const AddRepo = ({ onAddRepo }: { onAddRepo: () => void }) => {
  return (
    <div className="w-full h-full order-1 md:order-none">
      <button
        onClick={onAddRepo}
        className="space-y-10 relative transition-all w-full hover:-translate-y-3 h-full aspect-[4/3] xl:aspect-[1/1] py-6 px-5 rounded-2xl border-shade-border bg-[radial-gradient(50%_100%_at_50%_0%,#FAA7E0_0%,#EE46BC_100%)] flex flex-col"
      >
        <div className="relative w-full h-full pb-6">
          <div className="border border-dashed opacity-40 border-brand-background h-full w-full rounded-3xl"></div>
          <div className="absolute top-[calc(50%-12px)] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <AddRepoIcon />
          </div>
        </div>

        <div className="flex flex-col space-y-1 !mt-auto items-start">
          <div className="text-xl text-white font-semibold mt-auto">
            Add Repository
          </div>
          <p className="text-[#FCCEEE]">Submit via Pull Request</p>
        </div>
      </button>
    </div>
  );
};
