export default function ArrowTopRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <title>arrow-up-right</title>
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        stroke="currentColor"
      >
        <line x1="1.75" y1="10.25" x2="10.073" y2="1.927"></line>
        <polyline points="10.25 6.5 10.25 1.75 5.5 1.75"></polyline>
      </g>
    </svg>
  );
}
