export default function AddRepoIcon() {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0266 64.4444C26.0871 64.4444 22.4924 62.0729 20.9813 58.3289C20.4302 56.9636 21.088 55.4098 22.4568 54.8587C23.8222 54.3076 25.3724 54.9653 25.9271 56.3342C26.6986 58.2436 28.6542 59.3636 30.7022 59.0613L55.3208 55.4027C56.4942 55.2284 57.5324 54.6062 58.2364 53.6533C58.944 52.7004 59.2355 51.5271 59.0577 50.3538L55.3991 25.7351C55.1822 24.2773 56.192 22.9191 57.6462 22.7058C59.0791 22.4853 60.4586 23.4951 60.6791 24.9493L64.3377 49.568C64.7217 52.1529 64.0782 54.7307 62.5208 56.8284C60.9671 58.9262 58.688 60.2951 56.1031 60.6791L31.4844 64.3378C30.9937 64.4124 30.5102 64.4444 30.0266 64.4444Z"
        fill="#FDF2FA"
      />
      <path
        d="M42.2222 7.55556H17.3333C11.9395 7.55556 7.55554 11.9396 7.55554 17.3333V42.2222C7.55554 47.616 11.9395 52 17.3333 52H42.2222C47.616 52 52 47.616 52 42.2222V17.3333C52 11.9396 47.616 7.55556 42.2222 7.55556ZM38.6667 32.4444H32.4444V38.6667C32.4444 40.1387 31.2498 41.3333 29.7778 41.3333C28.3058 41.3333 27.1111 40.1387 27.1111 38.6667V32.4444H20.8889C19.4169 32.4444 18.2222 31.2498 18.2222 29.7778C18.2222 28.3058 19.4169 27.1111 20.8889 27.1111H27.1111V20.8889C27.1111 19.4169 28.3058 18.2222 29.7778 18.2222C31.2498 18.2222 32.4444 19.4169 32.4444 20.8889V27.1111H38.6667C40.1387 27.1111 41.3333 28.3058 41.3333 29.7778C41.3333 31.2498 40.1387 32.4444 38.6667 32.4444Z"
        fill="#FDF2FA"
      />
    </svg>
  );
}
