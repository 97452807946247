import React, { useState } from "react";
import { SearchInputProps } from "../types";
import SearchIcon from "./Icons/SearchIcon";

export const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  placeholder = "Search by repo or Github user...",
  className,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div
      className={`w-full px-4 border bg-white border-shade-border flex relative items-center space-x-2 text-shade-placeholder shadow-[0px_2px_2px_rgba(233,234,235,0.33)] rounded-2xl h-[42px] ${
        className || "max-w-[360px]"
      }`}
    >
      <div className="absolute left-4">
        <SearchIcon />
      </div>
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full indent-4 bg-transparent focus:outline-none focus:ring-0 h-full placeholder-shade-placeholder"
      />
    </div>
  );
};

const styles = `
  .text-gradient {
    background: linear-gradient(to right, black 65%, white 65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    caret-color: black;
  }
`;

export const StyleTag = () => <style>{styles}</style>;
