export default function CopyLinkIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 1.25V6.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 3.75H12.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49995 7.327C8.14795 7.495 7.81795 7.725 7.52695 8.017L7.51695 8.027C6.13595 9.408 6.13595 11.646 7.51695 13.027L9.69195 15.202C11.073 16.583 13.311 16.583 14.692 15.202L14.702 15.192C16.083 13.811 16.083 11.573 14.702 10.192L13.771 9.261"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49996 11.673C9.85196 11.505 10.182 11.275 10.473 10.983L10.483 10.973C11.864 9.592 11.864 7.354 10.483 5.973L8.30796 3.798C6.92696 2.417 4.68896 2.417 3.30796 3.798L3.29796 3.808C1.91696 5.189 1.91696 7.427 3.29796 8.808L4.22896 9.739"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
