import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
} from "react";
import { Repository, ProjectConfig, AppContextType } from "../types";
import devProjects from "../config/dev-projects.json";
import prodProjects from "../config/projects.json";
import { isDev } from "../config";

const AppContext = createContext<AppContextType>({} as AppContextType);
export const useAppContext = () => useContext(AppContext);

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Get all repositories from the appropriate config
  const allRepositories: Repository[] = useMemo(() => {
    const projectData = isDev ? devProjects : (prodProjects as ProjectConfig);
    return Object.values(projectData.projects).map((repo) => ({
      full_name: repo.full_name,
      repo_name: repo.repo_name,
      username: repo.username,
      repo_url: repo.repo_url,
    }));
  }, []);

  // Filtered repositories based on search term
  const searchResults = useMemo(() => {
    if (!searchTerm.trim()) {
      return allRepositories;
    }

    const searchLower = searchTerm.toLowerCase();
    return allRepositories.filter((repo) => {
      // search by repo_name or username and include hyphens
      const repo_nameSpaces = repo.repo_name.toLowerCase().replace(/-/g, " ");
      return (
        repo.repo_name.toLowerCase().includes(searchLower) ||
        repo_nameSpaces.includes(searchLower) ||
        repo.username.toLowerCase().includes(searchLower)
      );
    });
  }, [searchTerm, allRepositories]);

  const handleSearch = (query: string) => {
    setSearchTerm(query);
  };

  return (
    <AppContext.Provider
      value={{
        searchTerm,
        handleSearch,
        searchResults,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
