import { FaSpinner } from "react-icons/fa";
import { ButtonProps } from "../types";

export const Button = ({
  onClick,
  loading,
  disabled,
  children,
  className = "",
  theme = "outline",
}: ButtonProps) => {
  const baseStyles =
    "disabled:opacity-50 disabled:cursor-not-allowed flex space-x-1.5 transition hover:transition items-center justify-center rounded-xl py-2.5 px-3";

  // const variantStyles = secondary
  //   ? "text-darkGray border border-darkGray hover:border-hotPink hover:text-hotPink"
  //   : "bg-hotPink text-black hover:bg-darkHotPink";

  const themeStyles =
    theme === "outline"
      ? "bg-brand-background border text-brand-primary hover:border-opacity-30 border-brand-border border-opacity-15 font-medium"
      : theme === "secondary"
        ? "bg-shade-background text-shade-secondary hover:text-shade-primary"
        : "bg-brand-primary text-brand-background font-semibold hover:bg-brand-hover";

  return (
    <button
      onClick={onClick}
      disabled={loading || disabled}
      className={`${baseStyles} ${themeStyles} ${className}`}
    >
      {loading ? <FaSpinner className="inline-block animate-spin" /> : children}
    </button>
  );
};
